<template>
  <v-container fluid class="pt-0 pay-table-wrapper">
    <v-dialog v-model="document_blocked" persistent width="400">
      <v-card class="pt-4 pb-1">
        <v-card-text style="font-size: 1rem">
          {{ `Цей документ заблоковано користувачем ${document_blocked_data?.user} до ` }}
          <span class="font-weight-bold success--text">{{ document_blocked_data?.blocked_to | formatDate('DD.MM.YYYY HH:mm:ss') }}</span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pt-2">
          <v-spacer></v-spacer>
          <v-btn small depressed color="grey darken-2 white--text" @click.stop="goBack()">Повернутися</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="fill_dialog" max-width="600" v-if="current_month === header_month">
      <v-card>
        <v-toolbar class="grey darken-3" elevation="1" dark height="56">
          <v-toolbar-title>
            Заповнення відомості
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12">
              <AddressElementSelect v-model="filter.city"
                                    label="Населений пункт" filled
                                    select_type="city"
              />
            </v-col>
            <v-col cols="12">
              <AddressElementSelect :parent_id="filter.city" v-model="filter.streets"
                                    label="Вулиця(-ки)" multiple filled
                                    select_type="street" use_parent_id
                                    :disabled="!filter.city"
              />
            </v-col>
            <v-col cols="12">
              <AddressElementSelect :parent_id="filter.streets" v-model="filter.buildings"
                                    label="Будинок(-ки)" multiple filled
                                    select_type="building" use_parent_id
                                    :disabled="!filter.streets.length"
              />
            </v-col>
            <v-col cols="12">
              <v-checkbox v-model="filter.without_reading" color="success" label="Неподані покази" hide-details
                          class="mt-0"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed text color="secondary darken-1" @click.stop="fill_document" class="button-accept">
            <v-icon left>mdi-content-save</v-icon>
            Заповнити
          </v-btn>
          <v-btn depressed text tile color="grey" @click="fill_dialog = false">
            <v-icon left>mdi-close</v-icon>
            Відмінити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-col cols="12">
        <v-card>
          <v-toolbar tile elevation="0" outlined dense style="border: none">
            <v-toolbar-title class="font-weight-medium" v-if="$route.params.header_id === 'create'">Внесення показів
              (створення)
            </v-toolbar-title>
            <v-toolbar-title class="font-weight-medium" v-else>{{
                `Внесення показів №${$route.params.header_id} від ${formatDate(header_create_date)}`
              }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="shrink" v-if="$vuetify.breakpoint.smAndUp">
              <v-row class="align-center">
                <v-btn fab depressed small class="mr-2" @click="saveDocument" :loading="loading_saving"
                       :disabled="!documentReady || header_month !== current_month">
                  <v-icon color="grey darken-1">
                    mdi-content-save
                  </v-icon>
                </v-btn>
                <v-btn fab depressed small @click.stop="deleteDialog" :loading="loading_saving"
                       v-if="header_month === current_month"
                >
                  <v-icon color="error">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="6" md="3" order-sm="1" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
                <v-text-field label="Створено" hide-details readonly
                              :value="header_create_date | formatDate('DD.MM.YYYY HH:mm:ss')"
                              color="grey"
                              required
                              class="custom-text-field"/>
              </v-col>
              <v-col cols="6" md="3" order-sm="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                <date-component
                    :filled='false'
                    v-model="header_date" req label="Дата показів"
                    :class_="!header_date ? 'custom-text-field error-cs' : 'custom-text-field'"
                    :placeholder="'Оберіть дату'"
                    :height="56"/>
              </v-col>
              <v-col cols="12" md="2" order-sm="3" order="4" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                <v-text-field label="Всього" hide-details readonly v-model="header_total_sum"
                              v-decimal
                              color="grey"
                              class="custom-text-field"
                />
              </v-col>
              <v-col cols="12" md="4" order-sm="4" order="3" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                <v-select :items="services" label="Послуга" hide-details v-model="header_service"
                          color="grey"
                          required
                          :class="!header_service ? 'custom-text-field error-cs' : 'custom-text-field'"
                />
              </v-col>
              <v-col cols="12" class="pt-0" order-sm="5" order="5">
                <v-text-field label="Коментар" hide-details
                              v-model="header_comment"
                              color="grey"
                              class="custom-text-field"/>
              </v-col>
              <v-col cols="9" v-if="$vuetify.breakpoint.xs" order="6">
                <v-btn block depressed small @click="saveDocument" :loading="loading_saving" :disabled="!documentReady">
                  <v-icon small left color="grey darken-1" class="mr-2">
                    mdi-content-save
                  </v-icon>
                  Зберегти
                </v-btn>
              </v-col>
              <v-col cols="3" v-if="$vuetify.breakpoint.xs" order="7">
                <v-btn block depressed small :loading="loading_saving" @click.stop="deleteDialog">
                  <v-icon color="error">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="py-1">
        <v-card tile flat color="grey lighten-5" :disabled=" !header_service || !header_date">
          <v-btn small depressed color="grey lighten-3" @click="addNewRow" class="mr-2">
            <v-icon left size="22">
              mdi-plus
            </v-icon>
            Додати рядок
          </v-btn>
          <v-btn small depressed color="grey lighten-3" class="mr-2" @click="fill_dialog = true"
                 :disabled="!header_service">
            <v-icon left size="22">
              mdi-archive-arrow-up-outline
            </v-icon>
            Заповнити
          </v-btn>
          <v-btn small depressed color="error lighten-3" class="mr-2" @click="deleteRows"
                 :disabled="!selectedRows.length">
            <v-icon left size="22">
              mdi-delete
            </v-icon>
            Видалити рядок(-ки)
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="fill-height" style="overflow-x: auto;" :disabled=" !header_service">
          <div class="pay-table" v-if="!loading" :style="'pointer-events:none;' ? !header_service : ''">
            <div class="pay-table-header">
              <div class="pay-table-row" :class="$vuetify.breakpoint.xs ? 'xs-row' : ''">
                <div class="pay-table-col"></div>
                <div class="pay-table-col">
                  №
                </div>
                <div class="pay-table-col editable">
                  Особовий
                </div>
                <div class="pay-table-col">
                  Адреса
                </div>
                <div class="pay-table-col editable">
                  Дата
                </div>
                <div class="pay-table-col">
                  Лічильник
                </div>
                <div class="pay-table-col">
                  Зона
                </div>
                <div class="pay-table-col">
                  Попередній
                </div>
                <div class="pay-table-col editable">
                  Поточний
                </div>
                <div class="pay-table-col editable">
                  Різниця
                </div>
              </div>
            </div>
            <div class="pay-table-body">
              <div
                  class="pay-table-row"
                  v-for="(item, index) in filtered_items" :key="`item-${item.row_num}-${index}`"
                  :style="item.zone === 0 || item.zone === 1 || item.zone === null || item.zone === undefined ? '' : 'display: block;'"
              >
                <template v-if="item.zone === 0 || item.zone === 1 || item.zone === null || item.zone === undefined">
                  <div class="pay-table-col"
                       style="padding-left: 10px !important;"
                       :style="item.error
                                ? 'border-left: 6px solid var(--v-error-base);'
                                : item.value_set ? 'border-left: 6px solid var(--v-success-base);' : 'border-left: 6px solid var(--v-secondary-base);'"
                  >
                    <v-checkbox class="select-checkbox" color="success" hide-details :ripple="false"
                                @change="selectRow(item.row_num)"></v-checkbox>
                  </div>
                  <div class="pay-table-col">
                    {{ item.row_idx }}
                  </div>
                  <div class="pay-table-col editable">
                      <AUC_FlatSearch
                              :row_num="item.row_num"
                              :flat_id="item.flat_id"
                              :text_value="item.person_id"
                              :text_value_key="'person_id'"
                              :class="{'error-input': !item.person_id}"
                              @selectChange="flatChange"
                      />
                  </div>
                  <div class="pay-table-col">
                    <span>
                      {{ item.address }}
                    </span>
                  </div>
                  <div class="pay-table-col editable" style="position:relative;">
                    <input type="text" :value="item.date | formatDate" readonly
                           :class="{'error-input': !item.date}">
                    <div style="position:absolute; right: 18%; top: 50%; transform: translateY(-66%);">
                      <DateComponentIcon v-model="item.date" :row_num="item.row_num" @changeDate="changeDate"/>
                    </div>
                  </div>
                  <div class="pay-table-col editable">
                    <FlatCounter
                        :value="item.counter_id"
                        :flat_id="item.flat_id"
                        :text_value="item.counter_title"
                        :service_id="header_service"
                        :row_num="item.row_num"
                        :start_reading="item.start_meter_reading_first"
                        :start_reading_second="item.start_meter_reading_second"
                        :start_reading_third="item.start_meter_reading_third"
                        :counter_not_actual="item.counter_not_actual"
                        :clear="item.clear"
                        :zone="item.zone"
                        :filled="false"
                        :label="''"
                        :autosync="false"
                        :clearable="false"
                        :disabled="!item.flat_id"
                        @autocompleteChange="FlatCounterChange"
                        :class="{'error-input': !item.counter_id, 'table-input': true, 'not-actual': item.counter_not_actual}"
                        color="grey"/>
                  </div>
                  <div class="pay-table-col">
                    <span>
                      {{ item.zone === 0 ? 'Без зони' : item.zone === 1 ? 'Без зони' : '' }}
                    </span>
                  </div>
                  <div class="pay-table-col">
                    <span>
                      {{ item.start_meter_reading_first }}
                    </span>
                  </div>
                  <div class="pay-table-col editable">
                    <input type="text"
                           :value="item.current_meter_reading_first"
                           @input="item.current_meter_reading_first = +$event.target.value || 0"
                           @change="changeReading(false, 1, item)"
                           v-decimal>
                  </div>
                  <div class="pay-table-col editable">
                    <input type="text"
                           :value="item.difference_meter_reading_first"
                           @input="item.difference_meter_reading_first = +$event.target.value || 0"
                           @change="changeReading(true, 1, item)"
                           v-decimal>
                  </div>
                </template>
                <template v-else-if="item.zone === 2">
                  <div class="pay-table-row-expand-header" :class="{'expanded': item.row_num === expand_row_num}">
                    <div class="pay-table-col"
                         style="padding-left: 10px !important;"
                         :style="item.error ? 'border-left: 6px solid var(--v-error-base);' : 'border-left: 6px solid var(--v-success-base);'"
                    >
                      <v-checkbox class="select-checkbox" color="success" hide-details :ripple="false"
                                  @change="selectRow(item.row_num)"></v-checkbox>
                    </div>
                    <div class="pay-table-col">
                      <span>
                        {{ item.row_idx }}
                      </span>
                    </div>
                    <div class="pay-table-col editable">
                        <AUC_FlatSearch
                                :row_num="item.row_num"
                                :flat_id="item.flat_id"
                                :text_value="item.person_id"
                                :text_value_key="'person_id'"
                                :class="{'error-input': !item.person_id}"
                                @selectChange="flatChange"
                        />
                    </div>
                    <div class="pay-table-col">
                      <span>
                        {{ item.address }}
                      </span>
                    </div>
                    <div class="pay-table-col editable" style="position:relative;">
                      <input type="text" :value="item.date | formatDate" readonly
                             :class="{'error-input': !item.date}">
                      <div style="position:absolute; right: 18%; top: 50%; transform: translateY(-66%);">
                        <DateComponentIcon
                            v-model="item.date"
                            :row_num="item.row_num"
                            @changeDate="changeDate"
                        />
                      </div>
                    </div>
                    <div class="pay-table-col">
                      <FlatCounter
                          :value="item.counter_id"
                          :flat_id="item.flat_id"
                          :text_value="item.counter_title"
                          :service_id="header_service"
                          :row_num="item.row_num"
                          :start_reading="item.start_meter_reading_first"
                          :start_reading_second="item.start_meter_reading_second"
                          :start_reading_third="item.start_meter_reading_third"
                          :zone="item.zone"
                          :filled="false"
                          :label="''"
                          :autosync="false"
                          :clearable="false"
                          :counter_not_actual="item.counter_not_actual"
                          :disabled="!item.flat_id"
                          @autocompleteChange="FlatCounterChange"
                          :class="{'error-input': !item.counter_id, 'table-input': true}"
                          color="grey"/>
                    </div>
                    <div class="pay-table-col editable">
                      <v-btn icon small class="mr-3" @click="expandRow(item.row_num)">
                        <v-icon>
                          {{
                            item.row_num === expand_row_num ? 'mdi-arrow-up-bold-circle-outline' : 'mdi-arrow-down-bold-circle-outline'
                          }}
                        </v-icon>
                      </v-btn>
                      <div style="line-height: 25px; max-width: 220px">
                        День
                      </div>
                    </div>
                    <div class="pay-table-col">
                      <span>
                        {{ item.start_meter_reading_first }}
                      </span>
                    </div>
                    <div class="pay-table-col editable">
                      <input type="text"
                             :value="item.current_meter_reading_first"
                             @input="item.current_meter_reading_first = +$event.target.value || 0"
                             @change="changeReading(false, 1, item)"
                             v-decimal>
                    </div>
                    <div class="pay-table-col editable">
                      <input type="text"
                             :value="item.difference_meter_reading_first"
                             @input="item.difference_meter_reading_first = +$event.target.value || 0"
                             @change="changeReading(true, 1, item)"
                             v-decimal>
                    </div>
                  </div>
                  <div class="pay-table-row-expand-body" :class="{'expanded': item.row_num === expand_row_num}">
                    <div
                        class="pay-table-expand-body-row">
                      <div class="pay-table-col"></div>
                      <div class="pay-table-col" style="display: flex">
                        <div style="width: 28px; height: 28px;" class="mr-3"></div>
                        <div>Ніч</div>
                      </div>
                      <div class="pay-table-col">
                        <span>
                          {{ item.start_meter_reading_second }}
                        </span>
                      </div>
                      <div class="pay-table-col editable">
                        <input type="text"
                               :value="item.current_meter_reading_second"
                               @input="item.current_meter_reading_second = +$event.target.value || 0"
                               @change="changeReading(false, 2, item)"
                               v-decimal>
                      </div>
                      <div class="pay-table-col editable">
                        <input type="text"
                               :value="item.difference_meter_reading_second"
                               @input="item.difference_meter_reading_second = +$event.target.value || 0"
                               @change="changeReading(true, 2, item)"
                               v-decimal>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="item.zone === 3">
                  <div class="pay-table-row-expand-header" :class="{'expanded': item.row_num === expand_row_num}">
                    <div class="pay-table-col"
                         style="padding-left: 10px !important;"
                         :style="item.error ? 'border-left: 6px solid var(--v-error-base);' : 'border-left: 6px solid var(--v-success-base);'"
                    >
                      <v-checkbox class="select-checkbox" color="success" hide-details :ripple="false"
                                  @change="selectRow(item.row_num)"></v-checkbox>
                    </div>
                    <div class="pay-table-col">
                      <span>
                        {{ item.row_idx }}
                      </span>
                    </div>
                    <div class="pay-table-col editable">
                        <AUC_FlatSearch
                                :row_num="item.row_num"
                                :flat_id="item.flat_id"
                                :text_value="item.person_id"
                                :text_value_key="'person_id'"
                                :class="{'error-input': !item.person_id}"
                                @selectChange="flatChange"
                        />
                    </div>
                    <div class="pay-table-col">
                      <span>
                        {{ item.address }}
                      </span>
                    </div>
                    <div class="pay-table-col editable" style="position:relative;">
                      <input type="text" :value="item.date | formatDate" readonly
                             :class="{'error-input': !item.date}">
                      <div style="position:absolute; right: 18%; top: 50%; transform: translateY(-66%);">
                        <DateComponentIcon
                            v-model="item.date"
                            :row_num="item.row_num"
                            @changeDate="changeDate"
                        />
                      </div>
                    </div>
                    <div class="pay-table-col">
                      <FlatCounter
                          :value="item.counter_id"
                          :flat_id="item.flat_id"
                          :text_value="item.counter_title"
                          :service_id="header_service"
                          :row_num="item.row_num"
                          :start_reading="item.start_meter_reading_first"
                          :start_reading_second="item.start_meter_reading_second"
                          :start_reading_third="item.start_meter_reading_third"
                          :zone="item.zone"
                          :filled="false"
                          :label="''"
                          :autosync="false"
                          :clearable="false"
                          :counter_not_actual="item.counter_not_actual"
                          :disabled="!item.flat_id"
                          @autocompleteChange="FlatCounterChange"
                          :class="{'error-input': !item.counter_id, 'table-input': true}"
                          color="grey"/>
                    </div>
                    <div class="pay-table-col editable">
                      <v-btn icon small class="mr-3" @click="expandRow(item.row_num)">
                        <v-icon>
                          {{
                            item.row_num === expand_row_num ? 'mdi-arrow-up-bold-circle-outline' : 'mdi-arrow-down-bold-circle-outline'
                          }}
                        </v-icon>
                      </v-btn>
                      <div style="line-height: 25px; max-width: 220px">
                        День
                      </div>
                    </div>
                    <div class="pay-table-col">
                      <span>
                        {{ item.start_meter_reading_first }}
                      </span>
                    </div>
                    <div class="pay-table-col editable">
                      <input type="text"
                             :value="item.current_meter_reading_first"
                             @input="item.current_meter_reading_first = +$event.target.value || 0"
                             @change="changeReading(false, 1, item)"
                             v-decimal>
                    </div>
                    <div class="pay-table-col editable">
                      <input type="text"
                             :value="item.difference_meter_reading_first"
                             @input="item.difference_meter_reading_first = +$event.target.value || 0"
                             @change="changeReading(true, 1, item)"
                             v-decimal>
                    </div>
                  </div>
                  <div class="pay-table-row-expand-body" :class="{'expanded': item.row_num === expand_row_num}">
                    <div class="pay-table-expand-body-row">
                      <div class="pay-table-col"></div>
                      <div class="pay-table-col" style="display: flex">
                        <div style="width: 28px; height: 28px;" class="mr-3"></div>
                        <div>Ніч</div>
                      </div>
                      <div class="pay-table-col">
                        <span>
                          {{ item.start_meter_reading_second }}
                        </span>
                      </div>
                      <div class="pay-table-col editable">
                        <input type="text"
                               :value="item.current_meter_reading_second"
                               @input="item.current_meter_reading_second = +$event.target.value || 0"
                               @change="changeReading(false, 2, item)"
                               v-decimal>
                      </div>
                      <div class="pay-table-col editable">
                        <input type="text"
                               :value="item.difference_meter_reading_second"
                               @input="item.difference_meter_reading_second = +$event.target.value || 0"
                               @change="changeReading(true, 2, item)"
                               v-decimal>
                      </div>
                    </div>
                    <div class="pay-table-expand-body-row">
                      <div class="pay-table-col"></div>
                      <div class="pay-table-col" style="display: flex">
                        <div style="width: 28px; height: 28px;" class="mr-3"></div>
                        <div>Пік</div>
                      </div>
                      <div class="pay-table-col">
                        {{ item.start_meter_reading_third }}
                      </div>
                      <div class="pay-table-col editable">
                        <input type="text"
                               :value="item.current_meter_reading_third"
                               @input="item.current_meter_reading_third = +$event.target.value || 0"
                               @change="changeReading(false, 3, item)"
                               v-decimal>
                      </div>
                      <div class="pay-table-col editable">
                        <input type="text"
                               :value="item.difference_meter_reading_third"
                               @input="item.difference_meter_reading_third = +$event.target.value || 0"
                               @change="changeReading(true, 3, item)"
                               v-decimal>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="pay-table-pagination d-flex justify-start" v-if="table_items.length">
              <div class="pa-3 d-flex align-center justify-start">
                <v-btn text depressed icon class="mr-1"
                       @click.stop="pageStartOrEnd(true)"
                       :disabled="items_per_page > table_items.length || page === 1"
                >
                  <v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
                <v-btn text depressed icon
                       @click.stop="changePage(true)"
                       :disabled="page === 1"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <div class="mx-2">
                  <v-chip>
                    {{ `Сторінка: ${page} із ${pages}` }}
                  </v-chip>
                </div>
                <v-menu top :close-on-click="true" :close-on-content-click="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" class="mr-2">
                      {{ `${items_per_page} на стор.` }}
                    </v-chip>
                  </template>
                  <v-list>
                    <v-list-item
                        v-for="(item, index) in items_per_page_list"
                        :key="index"
                        @click="setItemsPerPage(item)"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn text depressed icon class="mr-1"
                       @click.stop="changePage(false)"
                       :disabled="items_per_page > table_items.length || page === pages"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn text depressed icon
                       @click.stop="pageStartOrEnd(false)"
                       :disabled="page === pages"
                >
                  <v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div v-if="!loading && !table_items.length" class="table-no-data">
            Для внесення оплат скористайтесь командою "Додати рядок"
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import readingDocumentAPI from "@/utils/axios/reading_document"
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";
import {maxBy} from 'lodash'
import {formatDate} from "@/filters";
import {QUESTION_SHOW} from "@/store/actions/question";
import clockAPI from "@/utils/axios/clock"

const modalDeleteId = 'payment_header_modal_delete'

export default {
  name: "CounterHeader",
  components: {
    DateComponentIcon: () => import("@/components/tableComponent/DateComponentIcon"),
    AUC_FlatSearch: () => import("@/components/tableComponent/AUC_FlatSearch.vue"),
    FlatCounter: () => import("@/components/autocomplite/FlatCounter"),
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithCounter',
      current_month: 'getCurrentMonth',
      settings: 'getGeneralSettings',
      modalAnswer: 'question_answer'
    }),
    documentReady() {
      let errors = 0
      this.table_items.forEach(item => {
        if (item.error) {
          errors += 1
        }
        if (!item.flat_id || !item.counter_id) {
          errors += 1
        }
      })

      return !errors
    },
    filtered_items() {
      return this.table_items.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
    }
  },
  data() {
    return {
      document_blocked: false,
      document_blocked_timeout: null,
      document_blocked_data: null,
      showConfirm: true,
      loading: false,
      loading_saving: false,
      expand_row_num: 0,
      errors: [],
      selectedRows: [],
      table_items: [],
      dataLoaded: false,
      header_date: '',
      header_service: null,
      header_create_date: '',
      header_total_sum: 0,
      header_month: null,
      header_comment: '',
      delete_watcher: null,
      fill_dialog: false,
      filter: {
        city: null,
        streets: [],
        buildings: [],
        without_reading: false
      },
      items_crud: {
        deleted: [],
        edited: [],
        added: []
      },
      pages: 0,
      items_per_page: 13,
      page: 0,
      items_per_page_list: [10, 12, 14, 16, 18, 20, 25, 50],
    }
  },
  methods: {
    changePage(left) {
      if (left) {
        this.page = this.page === 1 ? 1 : this.page - 1
      } else {
        this.page = this.page === this.pages ? this.pages : this.page + 1
      }
    },
    setPageSplitter() {
      this.pages = Math.ceil(this.table_items.length / this.items_per_page)
      this.page = this.pages ? 1 : 0

      if (this.pages * this.items_per_page - this.items_per_page > this.table_items.length) {
        this.pages -= 1
      }
    },
    setItemsPerPage(per_page) {
      this.items_per_page = per_page
      this.setPageSplitter()
    },
    pageStartOrEnd(start) {
      if (start) {
        this.page = 1
      } else {
        this.page = this.pages
      }
    },
    getEndOfMonth(date) {
      if (date) {
        const splited = date.split('-')
        const new_date = new Date(splited[0], +splited[1], 0)

        const year = new_date.getUTCFullYear()
        const month = new_date.getUTCMonth() + 1 < 10 ? '0' + (new_date.getUTCMonth() + 1) : new_date.getUTCMonth() + 1
        const day = new_date.getUTCDate() + 1 < 10 ? '0' + (new_date.getUTCDate() + 1) : new_date.getUTCDate() + 1
        return `${year}-${month}-${day}`
      }
    },
    getCounterDate(row_date, prev_date) {
      const date_row = row_date ? new Date(row_date) : null
      const date_prev = prev_date ? new Date(prev_date) : null
      const header_date = this.header_date ? new Date(this.header_date) : null
      // let end_of_month = this.getEndOfMonth(this.header_month)
      // end_of_month = end_of_month ? new Date(end_of_month) : null
      let result_date = null
      let add_day = false

      if (!date_row && !date_prev) {
        result_date = header_date
      }

      if (!date_row && date_prev) {
        if (header_date >= date_prev) {
          result_date = header_date
        }
        if (header_date < date_prev) {
          result_date = date_prev
          add_day = true
        }
      }

      if (date_row && !date_prev) result_date = date_row

      if (date_row && date_prev) {
        if (date_row >= date_prev) result_date = date_row
        if (date_row < date_prev) {
          result_date = date_prev
          add_day = true
        }
      }
      if (add_day) {
        result_date = result_date ? result_date.setDate(result_date.getDate() + 1) : null
      }

      if (!result_date) {
        return null
      } else {
        const year = result_date.getUTCFullYear()
        const month = result_date.getUTCMonth() + 1 < 10 ? '0' + (result_date.getUTCMonth() + 1) : result_date.getUTCMonth() + 1
        const day = result_date.getUTCDate() + 1 < 10 ? '0' + (result_date.getUTCDate() + 1) : result_date.getUTCDate()
        return `${year}-${month}-${day}`
      }
    },
    cityChange(payload) {
      this.filter.city = payload.value
    },
    streetChange(payload) {
      if (payload) {
        this.filter.streets = payload.map(item => item.value)
      } else {
        this.filter.streets = []
      }
    },
    buildingChange(payload) {
      if (payload) {
        this.filter.buildings = payload.map(item => item.value)
      } else {
        this.filter.buildings = []
      }
    },
    fill_document() {
      const payload = {
        ...this.filter,
        service_id: this.header_service
      }
      readingDocumentAPI.fill_document(payload)
          .then(response => response.data)
          .then(data => {
            const max_row_item = maxBy(this.table_items, 'row_num')
            const max_row_idx = maxBy(this.table_items, 'row_idx')

            let idx = 0
            let max_row = 0
            let max_idx = 0

            if (max_row_item) {
              max_row = max_row_item.row_num
            }
            if (max_row_idx) {
              max_idx = max_row_idx.row_idx
            }

            data.forEach(item => {
              const row_exist = this.table_items.find(r => r.flat_id === item.flat_id && r.counter_id === item.id)
              if (!row_exist) {
                idx += 1
                this.table_items.push(
                    {
                      row_num: max_row + idx,
                      row_idx: max_idx + idx,
                      address: item.address,
                      flat_id: item.flat_id,
                      date: this.getCounterDate(this.header_date, item.value_date),
                      is_group: false,
                      person_id: item.person_id,
                      counter_id: item.id,
                      counter_title: `№${item.serial_number}`,
                      start_meter_reading_first: item.current_meter_reading_first,
                      start_meter_reading_second: item.current_meter_reading_second,
                      start_meter_reading_third: item.current_meter_reading_third,
                      current_meter_reading_first: 0,
                      difference_meter_reading_first: 0,
                      zone: item.zone,
                      error: !this.header_date || !item.id || !item.flat_id,
                      value_set: false
                    }
                )
                this.items_crud.added.push(max_row + idx)
              }
            })
            this.setPageSplitter()
          })
          .finally(() => {
            this.fill_dialog = false
          })
    },
    formatDate,
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документа внесення показів № ${this.$route.params.header_id}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    changeReading(diff, zone, item) {
      if (zone === 1) {
        item.current_meter_reading_first = diff ? item.difference_meter_reading_first + item.start_meter_reading_first
            : item.current_meter_reading_first
        item.difference_meter_reading_first = diff ? item.difference_meter_reading_first
            : item.current_meter_reading_first - item.start_meter_reading_first
        item.value_set = true
      }
      if (zone === 2) {
        item.current_meter_reading_second = diff ? item.difference_meter_reading_second + item.start_meter_reading_second
            : item.current_meter_reading_second
        item.difference_meter_reading_second = diff ? item.difference_meter_reading_second
            : item.current_meter_reading_second - item.start_meter_reading_second
        item.value_set = true
      }
      if (zone === 3) {
        item.current_meter_reading_third = diff ? item.difference_meter_reading_third + item.start_meter_reading_third
            : item.current_meter_reading_third
        item.difference_meter_reading_third = diff ? item.difference_meter_reading_third
            : item.current_meter_reading_third - item.start_meter_reading_third
        item.value_set = true
      }

      const added = this.items_crud.added.includes(item.row_num)
      const edited = this.items_crud.edited.includes(item.row_num)

      if (!added && !edited) {
        this.items_crud.edited.push(item.row_num)
      }
      this.getTotals()
      this.checkRowForError(item)
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.loading_saving = true
                  readingDocumentAPI.remove(+this.$route.params.header_id)
                      .then(response => response.data)
                      .then(() => {
                        this.$store.dispatch(ALERT_SHOW, {text: 'Документ вилучено успішно', color: 'success'})
                        this.$router.push({name: 'ReadingDocument'})
                      })
                      .catch(err => {
                        const error = err.response.data.detail;
                        this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                      })
                      .finally(() => {
                        this.loading_saving = false
                      })
                }
              }
            }
          }
      )
    },
    expandRow(row_num) {
      this.expand_row_num = this.expand_row_num === row_num ? 0 : row_num
    },
    selectRow(row_num) {
      if (row_num) {
        if (this.selectedRows.indexOf(row_num) > -1) {
          this.selectedRows = this.selectedRows.filter(item => item !== row_num)
        } else {
          this.selectedRows.push(row_num)
        }
      }
    },
    changeDate(payload) {
      if (payload) {
        const row = this.table_items.find(item => item.row_num === payload.row_num)
        if (row) {
          row.date = payload.value
          this.checkRowForError(row)
        }
      }
    },
    flatChange(payload) {
      const current_row = this.table_items.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.address = payload.address || ''
        current_row.person_id = payload.person_id
        current_row.flat_id = payload.value
        current_row.zone = 0
        current_row.start_meter_reading_first = 0
        current_row.start_meter_reading_second = 0
        current_row.start_meter_reading_third = 0
        current_row.current_meter_reading_first = 0
        current_row.current_meter_reading_second = 0
        current_row.current_meter_reading_third = 0
        current_row.difference_meter_reading_first = 0
        current_row.difference_meter_reading_second = 0
        current_row.difference_meter_reading_third = 0
        current_row.counter_id = 0
        current_row.clear = true
        current_row.counter_not_actual = false
        this.checkRowForError(current_row)
      }
    },
    getTotals() {
      this.header_total_sum = this.table_items.reduce((acc, item) => {
        return acc + (item.difference_meter_reading_first || 0)
            + (item.difference_meter_reading_second || 0)
            + (item.difference_meter_reading_third || 0)
      }, 0)
    },
    FlatCounterChange(payload) {
      if (payload) {
        const row = this.table_items.find(item => item.row_num === payload.row_num)
        const counters = this.table_items.filter(item => item.counter_id === payload.value && item.row_num !== payload.row_num)

        if (row) {
          if (counters.length) {
            row.counter_id = 0
            row.counter_title = ''
            row.start_meter_reading_first = 0
            row.start_meter_reading_second = 0
            row.start_meter_reading_third = 0
            row.current_meter_reading_first = 0
            row.difference_meter_reading_first = 0
            row.zone = 0
            row.clear = true
            row.error = true
            row.counter_not_actual = false
            this.$store.dispatch(ALERT_SHOW, {
              text: `Рядок № ${row.row_num}: містить лічильник який вже присутній в таблиці. Подання показів по одному лічильнику більш ніж один раз - заборонено`,
              color: 'error'
            })
          } else {
            row.counter_id = payload.value
            row.counter_title = payload.text
            row.start_meter_reading_first = payload.current_meter_reading_first
            row.start_meter_reading_second = payload.current_meter_reading_second
            row.start_meter_reading_third = payload.current_meter_reading_third
            row.current_meter_reading_first = 0
            row.difference_meter_reading_first = 0
            row.zone = payload.zone
            row.clear = false
            row.counter_not_actual = payload.counter_not_actual || false
            row.date = this.getCounterDate(row.date, payload.value_date)
          }
        }
        this.checkRowForError(row)
      }
    },
    addNewRow() {
      const max_row_item = maxBy(this.table_items, 'row_num')
      const max_row_idx = maxBy(this.table_items, 'row_idx')

      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      let max_idx = 0
      if (max_row_idx) {
        max_idx = max_row_idx.row_idx
      }

      const new_row = {
        id: 0,
        flat_id: 0,
        date: this.header_date || null,
        is_group: false,
        person_id: "",
        address: "",
        counter_id: 0,
        zone: 0,
        start_meter_reading_first: 0,
        current_meter_reading_first: 0,
        difference_meter_reading_first: 0,
        row_num: max_row + 1,
        row_idx: max_idx + 1,
        counter_title: '',
        error: true,
        value_set: false,
        counter_not_actual: false
      }
      this.table_items.push(new_row)
      this.items_crud.added.push(new_row.row_num)
      this.setPageSplitter()
      this.page = this.pages
    },
    checkRowForError(item) {
      if (!item) return null;

      if (!item.flat_id) {
        item.error = true
        return null;
      }

      if (!item.counter_id) {
        item.error = true
        return null;
      }

      if (!item.date) {
        item.error = true
        return null;
      }

      // if (item.zone === 0 || item.zone === 1 || item.zone === null || item.zone === undefined) {
      //   if (item.start_meter_reading_first > 0 && (item.current_meter_reading_first || 0) === 0 && (item.difference_meter_reading_first || 0) === 0) {
      //     item.error = true
      //     return null;
      //   }
      // }
      //
      //
      // if (item.zone === 2) {
      //   if (item.start_meter_reading_first > 0 && (item.current_meter_reading_first || 0) === 0 && (item.difference_meter_reading_first || 0) === 0) {
      //     item.error = true
      //     return null;
      //   }
      //
      //   if (item.start_meter_reading_second > 0 && (item.current_meter_reading_second || 0) === 0 && (item.difference_meter_reading_second || 0) === 0) {
      //     item.error = true
      //     return null;
      //   }
      // }
      //
      //
      // if (item.zone === 3) {
      //   if (item.start_meter_reading_first > 0 && (item.current_meter_reading_first || 0) === 0 && (item.difference_meter_reading_first || 0) === 0) {
      //     item.error = true
      //     return null;
      //   }
      //
      //   if (item.start_meter_reading_second > 0 && (item.current_meter_reading_second || 0) === 0 && (item.difference_meter_reading_second || 0) === 0) {
      //     item.error = true
      //     return null;
      //   }
      //
      //   if (item.start_meter_reading_third > 0 && (item.current_meter_reading_third || 0) === 0 && (item.difference_meter_reading_third || 0) === 0) {
      //     item.error = true
      //     return null;
      //   }
      // }

      item.error = false
    },
    deleteRows() {
      this.selectedRows.forEach(item => {
        const tab_item = this.table_items.find(tab_item => tab_item.row_num === item)
        if (tab_item) {
          const idx = this.table_items.indexOf(tab_item)
          const added = this.items_crud.added.includes(tab_item.row_num)
          if (!added) {
            if (tab_item.id) {
              this.items_crud.deleted.push(tab_item.id)
            }
          } else {
            const add_idx = this.items_crud.added.indexOf(tab_item.row_num)
            this.items_crud.added.splice(add_idx, 1)
          }
          this.table_items.splice(idx, 1)
        }
      })
      this.table_items.forEach((item, idx) => {
        item['row_idx'] = idx + 1
      })
      this.selectedRows = []
      this.getTotals()
      this.setPageSplitter()
    },
    constructRowsForSave(row_type) {
      if (row_type === 'added' || row_type === 'edited') {
        return this.items_crud[row_type].map(item => {
          const row = this.table_items.find(i => i.row_num === item)
          return {
            flat_id: row.flat_id,
            date: row.date,
            header_id: this.header_id || 0,
            counter_id: row.counter_id,
            start_meter_reading_first: row.start_meter_reading_first,
            start_meter_reading_second: row.start_meter_reading_second,
            start_meter_reading_third: row.start_meter_reading_third,
            current_meter_reading_first: row.current_meter_reading_first,
            current_meter_reading_second: row.current_meter_reading_second,
            current_meter_reading_third: row.current_meter_reading_third,
            difference_meter_reading_first: row.difference_meter_reading_first,
            difference_meter_reading_second: row.difference_meter_reading_second,
            difference_meter_reading_third: row.difference_meter_reading_third,
            value_set: row.value_set,
            id: row.id
          }
        })
      }

      if (row_type === 'deleted') {
        return this.items_crud.deleted.map(item => {
          return {id: item}
        })
      }
    },
    saveDocument() {
      const is_create = this.$route.params.header_id === 'create'

      const payload = {
        header: {
          reading_date: this.header_date,
          total_sum: this.header_total_sum,
          total_row: this.table_items.length,
          date: this.header_create_date,
          service_id: this.header_service,
          month: this.header_month,
          comment: this.header_comment
        },
      }

      if (!is_create) {
        payload['table_added'] = this.constructRowsForSave('added')
        payload['table_edited'] = this.constructRowsForSave('edited')
        payload['table_deleted'] = this.constructRowsForSave('deleted')
        payload.header['id'] = +this.$route.params.header_id
      } else {
        payload['table'] = this.constructRowsForSave('added')
      }

      if (is_create) {
        this.loading_saving = true
        readingDocumentAPI.create(payload)
            .then(response => response.data)
            .then(data => {
              if (data) {
                const header_id = +data
                this.showConfirm = false
                this.items_crud.added = []
                this.items_crud.deleted = []
                this.items_crud.edited = []
                if (!this.document_blocked_timeout) {
                  this.blockTimeout()
                }
                this.$store.commit(ALERT_SHOW, {text: 'Покази успішно створено', color: 'success'})
                this.$router.push(
                    {
                      name: 'ReadingDocumentHeader',
                      params: {header_id: header_id}
                    }
                )
                this.renew()
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.loading_saving = false
            })
      } else {
        this.loading_saving = true
        readingDocumentAPI.update(payload)
            .then(response => response.data)
            .then(data => {
              if (data) {
                this.items_crud.added = []
                this.items_crud.deleted = []
                this.items_crud.edited = []
                this.$store.commit(ALERT_SHOW, {text: 'Покази успішно збережені', color: 'success'})
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.loading_saving = false
            })
      }
    },
    blockTimeout() {
      const vm = this
      const is_create = this.$route.params.header_id === 'create'
      if (!is_create) {
        this.document_blocked_timeout = setInterval(() => {
          vm.blockDocument()
        },23000)
      }
    },
    blockDocument(header_id=null) {
      let local_header_id = header_id
      if (!local_header_id) {
        const is_created = this.$route.params.header_id !== 'create'
        if (is_created) {
          local_header_id = +this.$route.params.header_id
        }
      }
      if (local_header_id) {
        clockAPI.block({
          object_name: 'document_flat_counter_reading',
          object_id: local_header_id
        })
            .then(response => response.data)
            .then(data => {
              const prev_block = this.document_blocked
              this.document_blocked = data.blocked
              this.document_blocked_data = data

              if (prev_block === true && this.document_blocked === false) {
                this.renew()
                this.document_blocked_timeout = null
                this.blockDocument()
                this.blockTimeout()
              }
            })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
      }
    },
    unblockDocument(header_id=null) {
      let local_header_id = header_id
      if (!local_header_id) {
        const is_created = this.$route.params.header_id !== 'create'
        if (is_created) {
          local_header_id = +this.$route.params.header_id
        }
      }
      if (local_header_id) {
        clockAPI.unblock({
          object_name: 'document_flat_counter_reading',
          object_id: local_header_id
        })
            .then(response => response.data)
            .then(() => {
              this.document_blocked = false
              this.document_blocked_data = null
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            })
      }
    },
    goBack() {
      this.showConfirm = false
      history.back()
    },
    renew(block=false) {
      const header_id = +this.$route.params.header_id
      readingDocumentAPI.get_by_header(header_id)
          .then(response => response.data)
          .then(data => {
            this.pages = 0
            this.page = 0

            this.items_crud.added = []
            this.items_crud.deleted = []
            this.items_crud.edited = []

            if (data) {
              const header = data.header

              this.header_create_date = header.date
              this.header_date = header.reading_date
              this.header_service = header.service_id
              this.header_total_sum = header.total_sum
              this.header_month = header.month
              this.header_comment = header.comment
              const rows = data.rows

              this.table_items = rows.map((item, idx) => {
                return Object.assign(item, {row_num: idx + 1, error: false, row_idx: idx + 1})
              })
            }
            this.setPageSplitter()
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
          .finally(() => {
            this.loading = false
            if (block) {
              this.blockDocument(header_id)
              if (!this.document_blocked_timeout) {
                this.blockTimeout()
              }
            }
          })
    },
  },
  created() {
    this.watch_modal_answer()
    if (this.$route.params.header_id) {
      this.loading = true
      this.showConfirm = true
      const is_created = this.$route.params.header_id !== 'create'

      if (is_created) {
        this.renew(true)
      } else {
        const month = this.$route.query.month
        this.header_create_date = formatDate(Date(), 'YYYY-MM-DD HH:mm:ss')
        this.header_total_sum = 0
        this.header_month = month
        this.header_bank = 0
        this.header_date = null
        this.header_by_bank = false
        this.loading = false
      }
    }
  },
  beforeDestroy() {
    if (this.delete_watcher) {
      this.delete_watcher()
    }
    if (this.document_blocked_timeout) {
      clearInterval(this.document_blocked_timeout)
      this.unblockDocument()
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.showConfirm) {
      const answer = window.confirm('Ви дійсно хочете закрити поточну сторінку. Незбережені дані будуть втрачені')
      if (answer) {
        clearInterval(this.document_blocked_timeout)
        this.unblockDocument()
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>

<style scoped lang="scss">
.pay-table-col {
  &:nth-child(1) {
    flex: 0 1 60px;
    padding-left: 16px !important;
    min-width: 60px;
  }

  &:nth-child(2) {
    flex: 0 1 50px;
    min-width: 50px;
  }

  &:nth-child(3) {
    flex: 0 1 110px;
    min-width: 110px;
  }

  &:nth-child(4) {
    flex: 0 1 250px;
    min-width: 190px;
  }

  &:nth-child(5) {
    flex: 0 1 120px;
    min-width: 110px;
  }

  &:nth-child(6) {
    flex: 0 1 200px;
    min-width: 180px;
  }

  &:nth-child(7) {
    flex: 0 1 110px;
    min-width: 90px;
    display: flex;
  }

  &:nth-child(8) {
    flex: 0 1 120px;
    min-width: 120px;
  }

  &:nth-child(9) {
    flex: 0 1 120px;
    min-width: 120px;
  }

  &:nth-child(10) {
    flex: 0 1 100px;
    min-width: 100px;
  }

  //&:nth-child(10) {
  //  flex: 1;
  //  display: flex;
  //  flex-wrap: nowrap;
  //  flex-direction: row;
  //  min-width: 180px;
  //}
}

.pay-table-row-expand-body {
  display: none;
  max-height: 0;

  .pay-table-expand-body-row {
    //border-top: thin solid $border-color;
    .pay-table-col {
      &:nth-child(1) {
        flex: 0 1 790px;
        min-width: 700px;
      }

      &:nth-child(2) {
        flex: 0 1 110px;
        min-width: 90px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
      }

      &:nth-child(3) {
        flex: 0 1 120px;
        min-width: 120px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
      }

      &:nth-child(4) {
        flex: 0 1 120px;
        min-width: 120px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
      }

      &:nth-child(5) {
        flex: 0 1 100px;
        min-width: 100px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
      }
    }
  }
}
</style>